import React from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUpRightAndDownLeftFromCenter,
  faHand,
  faEdit,
  faRotate,
  faArrowRotateLeft,
  faArrowRotateRight,
  faCartShopping,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import {getZoomInIcon, getZoomOutIcon} from '../../helpers/iconHelper';
import * as S from './styled';

export const Footer = props => {
  const {
    size,
    setSize,
    onFitToPageClicked,
    onAddToCartClick,
    onPreviewClick,
    editMode,
    setEditMode,
    canvasRotation,
    setCanvasRotation,
    isAddToCartDisabled,
    isVisibleAddToCart,
    sideBarWidth,
    isVertical,
    windowWidth,
    onRedo,
    onUndo,
    isUndoActive,
    isRedoActive,
    isShowPreviewButton,
    templateLayers,
    selectedLayer,
    onChangeLayer,
    footerRef,
  } = props;
  const {t} = useTranslation(['footer']);
  const theme = useTheme();
  const prevLayout = templateLayers.find(
    i => i?.index === selectedLayer?.index - 1,
  );
  const nextLayout = templateLayers.find(
    i => i?.index === selectedLayer?.index + 1,
  );

  const editModesContainer = (
    <div>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => setEditMode(true)}
        color={editMode ? theme.primaryColor : 'lightGray'}
      />
      <FontAwesomeIcon
        icon={faHand}
        onClick={() => setEditMode(false)}
        color={!editMode ? theme.primaryColor : 'lightGray'}
      />
    </div>
  );

  const rotateButton = (
    <S.RotateIconWrapper
      onClick={() => {
        //setCanvasRotation(canvasRotation < 270 ? canvasRotation + 90 : 0)
      }}>
      <FontAwesomeIcon
        className={'rotate-icon'}
        icon={faRotate}
        color={theme.primaryColor}
      />
    </S.RotateIconWrapper>
  );

  const redoUndoButtons = (
    <S.RedoUndoWrapper $undoActive={isUndoActive} $redoActive={isRedoActive}>
      <FontAwesomeIcon
        className="svg-left"
        icon={faArrowRotateLeft}
        onClick={onUndo}
      />
      <FontAwesomeIcon
        className="svg-right"
        icon={faArrowRotateRight}
        onClick={onRedo}
      />
    </S.RedoUndoWrapper>
  );

  const zoomInButton = (
    <S.ZoomIconWrapper
      onClick={() => {
        if (size > 0) {
          setSize(size - 1);
        }
      }}>
      <S.ZoomIcon src={getZoomInIcon('black')} alt={'zoomInIcon'} />
    </S.ZoomIconWrapper>
  );

  const zoomOutButton = (
    <S.ZoomIconWrapper
      onClick={() => {
        if (size < 100) {
          setSize(size + 1);
        }
      }}>
      <S.ZoomIcon src={getZoomOutIcon('black')} alt={'zoomOutIcon'} />
    </S.ZoomIconWrapper>
  );

  const resizeButton = (
    <S.ResizeIconWrapper>
      <FontAwesomeIcon
        className={'resize-icon'}
        icon={faUpRightAndDownLeftFromCenter}
        onClick={onFitToPageClicked}
      />
    </S.ResizeIconWrapper>
  );

  const previewButton = isShowPreviewButton ? (
    <S.PreviewButton>
      <button onClick={onPreviewClick}>
        <S.InsideButton>
          <FontAwesomeIcon icon={faEye} className={'button-icon'} />
          {t('Preview')}
        </S.InsideButton>
      </button>
    </S.PreviewButton>
  ) : null;

  const getStepButtonContent = (title, layout) => {
    return (
      <S.StepRow>
        <S.StepCol>
          <S.StepTitle>{title}</S.StepTitle>
          <S.StepLayoutName>
            {t('Design')} {layout?.description || layout?.name}
          </S.StepLayoutName>
        </S.StepCol>
        <S.StepNumber>
          ({layout?.order}/{templateLayers?.length})
        </S.StepNumber>
      </S.StepRow>
    );
  };

  const prevStepButton = prevLayout ? (
    <S.PrevStepButton>
      <button onClick={() => onChangeLayer(prevLayout)}>
        {getStepButtonContent(t('Prev. Step'), prevLayout)}
      </button>
    </S.PrevStepButton>
  ) : null;

  const nextStepButton = nextLayout ? (
    <S.NextStepButton>
      <button onClick={() => onChangeLayer(nextLayout)}>
        {getStepButtonContent(t('Next Step'), nextLayout)}
      </button>
    </S.NextStepButton>
  ) : null;

  const addToCartButton = isVisibleAddToCart ? (
    <S.AddToCart disabled={isAddToCartDisabled}>
      <button disabled={isAddToCartDisabled} onClick={onAddToCartClick}>
        <S.InsideButton>
          <FontAwesomeIcon icon={faCartShopping} className={'button-icon'} />
          {t('Add to cart')}
        </S.InsideButton>
      </button>
    </S.AddToCart>
  ) : null;

  const zoomButtonsContainer = (
    <S.CounterWr>
      {zoomInButton}
      {zoomOutButton}
      {/*{resizeButton}*/}
    </S.CounterWr>
  );

  const addAndPrevButtonsContainer = (
    <S.AddAndPrevButtons $isVertical={isVertical}>
      {previewButton}
      {prevStepButton}
      {nextStepButton}
      {addToCartButton}
    </S.AddAndPrevButtons>
  );

  const iconsContainer = (
    <S.IconsRow>
      {/*<S.EditModeWr>{rotateButton}</S.EditModeWr>*/}
      {redoUndoButtons}
      {/*{zoomButtonsContainer}*/}
      {/*<div style={{width: 30}} />*/}
    </S.IconsRow>
  );

  return (
    <S.Footer $isVertical={isVertical} ref={footerRef}>
      <S.CanvasButtonsArea $isVertical={isVertical}>
        {iconsContainer}
        {addAndPrevButtonsContainer}
        <div style={{width: 0, height: 0, flex: 1}} />
      </S.CanvasButtonsArea>
    </S.Footer>
  );
};
