import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faClose} from '@fortawesome/free-solid-svg-icons';
import {LayoutsDetails} from './components/LayoutsDetails';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as S from './styled';

export const Header = props => {
  const {name, templateLayers, selectedLayer, onChangeLayer, onBack, onClose, isVertical, sideBarWidth} =
    props;
  const {width: windowWidth} = useWindowDimensions();

  const layoutDetails = (
    <LayoutsDetails
      data={templateLayers}
      active={selectedLayer}
      short={windowWidth < 700}
      onChange={v => onChangeLayer(v)}
    />
  );

  return (
    <S.Header>
      <S.BackBlock onClick={onBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </S.BackBlock>
      <S.TitleWithLayerSelector>
        <S.HeaderTitle $isVertical={isVertical} $sideBarWidth={sideBarWidth}>{name || '-'}</S.HeaderTitle>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {templateLayers?.length > 1 ? layoutDetails : null}
        </div>
        <S.CloseBlock onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </S.CloseBlock>
      </S.TitleWithLayerSelector>
    </S.Header>
  );
};
