import React from 'react';
import {useTranslation} from 'react-i18next';
import * as S from './styled';

export const LayoutsDetails = props => {
  const {data, active, short = false, onChange} = props;
  const {t} = useTranslation();

  const listContainer = data.map(i => (
    <S.Item key={i?.order} onClick={() => onChange(i)}>
      <S.Circle $active={i?.id === active?.id}>{i?.order}</S.Circle>
      {!short ? (
        <S.Title>
          <S.DesignText>{t('Design')}</S.DesignText>
          <S.DescriptionName>{i?.description || i?.name}</S.DescriptionName>
        </S.Title>
      ) : null}
    </S.Item>
  ));

  return <S.Row>{listContainer}</S.Row>;
};
