import {Group, Rect, Text} from 'react-konva';
import {useSelector} from 'react-redux';
import {getValueByUnit, unitKeys} from '../../helpers/unitHelper';

export const CanvasRuler = props => {
  const {
    width,
    height,
    rulerWidth,
    rulerColor = '#454545',
    lineWeight = 14,
    dpi = 96,
  } = props;

  const bgColor = '#f6f6f6';
  const rulerBgColor = bgColor;
  const labelFontSize = rulerWidth / 2;

  const getPaddingValueForHorizontalLabels = v => {
    const length = String(v)?.length;
    let ratio = 1;
    switch (length) {
      case 1:
        ratio = 0.8;
        break;
      case 2:
        ratio = 1.2;
        break;
      case 3:
        ratio = 1.8;
        break;
      case 4:
        ratio = 2.3;
        break;
      case 5:
        ratio = 2.8;
        break;
      case 6:
        ratio = 3.3;
        break;
      case 7:
        ratio = 3.8;
        break;
      case 8:
        ratio = 4.3;
        break;
      case 9:
        ratio = 4.8;
        break;
      default:
        break;
    }
    return lineWeight / 2 + labelFontSize * ratio;
  };

  const getScaleContainer = () => {
    const selectedUnit = useSelector(state => state.settings.unit);
    const stepWidth10inPx = Math.min(width, height) / 10;
    const stepWidth10inCurrency = getValueByUnit(
      stepWidth10inPx,
      unitKeys.pixels,
      selectedUnit,
      dpi
    );
    const stepWidth =
      stepWidth10inCurrency <= 1
        ? getValueByUnit(1, selectedUnit, unitKeys.pixels, dpi)
        : getValueByUnit(
            Math.round(stepWidth10inCurrency),
            selectedUnit,
            unitKeys.pixels, dpi
          );
    const hStepCount = parseInt((height || 0) / stepWidth) + 1;
    const vStepCount = parseInt((width || 0) / stepWidth) + 1;
    const arrayH = (hStepCount ? [...Array(hStepCount)] : [])
      .map((i, index) => (index + 1) * stepWidth)
      .filter(i => i < height);
    const arrayV = (vStepCount ? [...Array(vStepCount)] : [])
      .map((i, index) => (index + 1) * stepWidth)
      .filter(i => i < width);
    const isLargeRulerLine = (index, length) => {
      return (
        index === 0 ||
        ((index + 1) / 5 === parseInt((index + 1) / 5) && index !== length - 1)
      );
    };

    const verticalScaleLines = arrayH.map((i, index) => {
      return (
        <Rect
          key={`vsl${index}`}
          fill={rulerColor}
          x={isLargeRulerLine(index, arrayH?.length) ? 0 : rulerWidth / 2}
          y={
            i + rulerWidth - lineWeight / 2
            //(index === arrayH?.length - 1 ? lineWeight : lineWeight / 2)
          }
          width={
            isLargeRulerLine(index, arrayH?.length)
              ? rulerWidth
              : rulerWidth / 2
          }
          height={lineWeight}
        />
      );
    });
    const horizontalScaleLines = arrayV.map((i, index) => {
      return (
        <Rect
          key={`hsl${index}`}
          fill={rulerColor}
          x={
            i + rulerWidth - lineWeight / 2
            //(index === arrayV?.length - 1 ? lineWeight : lineWeight / 2)
          }
          y={isLargeRulerLine(index, arrayV?.length) ? 0 : rulerWidth / 2}
          width={lineWeight}
          height={
            isLargeRulerLine(index, arrayV?.length)
              ? rulerWidth
              : rulerWidth / 2
          }
        />
      );
    });
    const verticalScaleLabels = arrayH.map((i, index) => {
      return isLargeRulerLine(index, arrayH?.length) ? (
        <Text
          key={`vst${index}`}
          fill={rulerColor}
          x={isLargeRulerLine(index, arrayH?.length) ? 0 : rulerWidth / 2}
          y={i + rulerWidth - lineWeight / 2 - labelFontSize * 1.2}
          fontSize={labelFontSize}
          text={getValueByUnit(i, unitKeys.pixels, selectedUnit, dpi)}
        />
      ) : null;
    });
    const horizontalScaleLabels = arrayV.map((i, index) => {
      return isLargeRulerLine(index, arrayV?.length) ? (
        <Text
          key={`hst${index}`}
          fill={rulerColor}
          x={
            i +
            rulerWidth -
            getPaddingValueForHorizontalLabels(
              getValueByUnit(i, unitKeys.pixels, selectedUnit, dpi),
            )
          }
          y={isLargeRulerLine(index, arrayV?.length) ? 0 : rulerWidth / 2}
          fontSize={labelFontSize}
          text={getValueByUnit(i, unitKeys.pixels, selectedUnit, dpi)}
        />
      ) : null;
    });
    const unitNameLabel = (
      <Text
        key={`unitName`}
        fill={rulerColor}
        x={0}
        y={rulerWidth / 2}
        fontSize={labelFontSize}
        text={selectedUnit}
      />
    );

    const totalWidthLabel = (
      <Text
        key={`totalWidthLabel`}
        fill={rulerColor}
        x={
          width +
          rulerWidth -
          getPaddingValueForHorizontalLabels(
            (parseFloat(
              getValueByUnit(width, unitKeys.pixels, selectedUnit, dpi),
            ).toFixed(2) *
              100) /
              100,
          )
        }
        y={0}
        fontSize={labelFontSize}
        fontStyle={'bold'}
        text={
          (parseFloat(
            getValueByUnit(width, unitKeys.pixels, selectedUnit, dpi),
          ).toFixed(2) *
            100) /
          100
        }
      />
    );

    const totalHeightLabel = (
      <Text
        key={`totalHeightLabel`}
        fill={rulerColor}
        x={0}
        y={height + rulerWidth + lineWeight * 4}
        fontSize={labelFontSize}
        fontStyle={'bold'}
        text={
          (parseFloat(
            getValueByUnit(height, unitKeys.pixels, selectedUnit, dpi),
          ).toFixed(2) *
            100) /
          100
        }
      />
    );

    const totalWidthScaleLine = (
      <Rect
        key={`totalWidthScaleLine`}
        fill={rulerColor}
        x={width + rulerWidth - lineWeight / 2}
        y={0}
        width={lineWeight}
        height={rulerWidth}
      />
    );

    const totalHeightScaleLine = (
      <Rect
        key={`totalHeightScaleLine`}
        fill={rulerColor}
        x={0}
        y={height + rulerWidth - lineWeight / 2}
        width={rulerWidth}
        height={lineWeight}
      />
    );

    const internalBorder = (
      <Rect
        key={`internalBorder`}
        stroke={rulerColor}
        strokeWidth={lineWeight / 2}
        x={rulerWidth}
        y={rulerWidth}
        width={width}
        height={height}
        fillEnabled={false}
      />
    );

    return [
      ...horizontalScaleLines,
      ...verticalScaleLines,
      ...horizontalScaleLabels,
      ...verticalScaleLabels,
      unitNameLabel,
      totalWidthLabel,
      totalHeightLabel,
      totalWidthScaleLine,
      totalHeightScaleLine,
      internalBorder,
    ];
  };

  return <Group>{getScaleContainer()}</Group>;
};
