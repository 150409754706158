import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-right: 15px;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props =>
    props.$active ? props.theme.primaryColor : '#fff'};
  color: ${props => (props.$active ? '#fff' : props.theme.primaryColor)};
  border: 1px solid ${props => props.theme.primaryColor};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80px;
  font-size: 14px;
  //color: ${props => props.theme.primaryColor};
  text-transform: capitalize;
`;

export const DesignText = styled.div``;

export const DescriptionName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
